<template>
  <div class="DomainCreation" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Ajouter un nouveau domaine</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Une erreur s'est produite">
              <div class="text-center">
                <p>Une erreur s'est produite,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="DomainCreatedModal" id="DomainCreatedModal" size="lg" hide-footer title="Domaine créé">
              <div class="text-center">
                <p><strong>Votre domaine a bien été créé ! </strong></p>

                <b-button class="mt-3" block pull variant="outline-dark" @click="$router.push('/domains')">
                  Retour à la liste des domaines
                </b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#F9BF3B">
            </loading>

            <div class="text-right">
              <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
            </div>
            <b-form v-on:submit.prevent="createDomain()">

              <div class="row">
                <div class="col-sm-6 col-12">
                  <b-form-group id="create-domain-name-group" label="Nom" label-for="create-domain-name-input" class="mandatory-input">
                    <b-form-input
                      v-model="createDomainName" type="text"
                      maxlength="250"
                      placeholder="Nom (Ex : Le Monde)"
                      id="create-domain-name-input"
                      @input="$v.createDomainName.$touch()"
                      :state="$v.createDomainName.$dirty ? !$v.createDomainName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.createDomainName.$error">Votre nom doit faire entre 2 et 250 caractères</div>
                  </b-form-group>
                </div>
                <div class="col-sm-6 col-12">
                  <b-form-group id="create-domain-domain_name-group" label="Nom de domaine" label-for="create-domain-domain_name-input" class="mandatory-input">
                    <b-form-input
                      v-model="createDomainDomainName" type="text"
                      maxlength="250"
                      placeholder="Nom de domaine (Ex : https://lemonde.fr)"
                      id="create-domain-domain_name-input"
                      @input="$v.createDomainDomainName.$touch()"
                      :state="$v.createDomainDomainName.$dirty ? !$v.createDomainDomainName.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.createDomainDomainName.$error">Votre nom doit faire entre 2 et 250 caractères</div>
                  </b-form-group>
                </div>
              </div>

              <b-form-group id="create-domain-type-group"
                label="Type de presse"
                label-for="create-domain-type-input"
                class="mandatory-input">
                <b-form-select
                  v-model="createDomainType"
                  id="create-domain-type-input"
                  @input="$v.createDomainType.$touch()"
                  :state="$v.createDomainType.$dirty ? !$v.createDomainType.$error : null"
                  :options='createDomainTypeOptions'>
                </b-form-select>
              </b-form-group>


              <b-form-group id="create-domain-url_login-group" label="URL de la page de login" label-for="create-domain-url_login-input" class="mandatory-input">
                <b-form-input
                  v-model="createDomainUrlLogin" type="text"
                  maxlength="1023"
                  placeholder="Url du login (Ex : https://lemonde.fr/login)"
                  id="create-domain-url_login-input"
                  @input="$v.createDomainUrlLogin.$touch()"
                  :state="$v.createDomainUrlLogin.$dirty ? !$v.createDomainUrlLogin.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createDomainUrlLogin.$error">Votre url doit faire entre 2 et 1024 caractères</div>
              </b-form-group>

              <div class="row">
                <div class="col-sm-6 col-12">
                  <b-form-group id="create-domain-login-group" label="Identifiant" label-for="create-domain-login-input" class="mandatory-input">
                    <b-form-input
                      v-model="createDomainLogin" type="text"
                      maxlength="250"
                      placeholder="Identifiant"
                      id="create-domain-login-input"
                      @input="$v.createDomainLogin.$touch()"
                      :state="$v.createDomainLogin.$dirty ? !$v.createDomainLogin.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.createDomainLogin.$error">Votre identifiant doit faire entre 2 et 255 caractères</div>
                  </b-form-group>
                </div>
                <div class="col-sm-6 col-12">
                  <b-form-group id="create-domain-password-group" label="Mot de passe" label-for="create-domain-password-input" class="mandatory-input">
                    <b-form-input
                      v-model="createDomainPassword" type="text"
                      maxlength="250"
                      placeholder="Mot de passe"
                      id="create-domain-password-input"
                      @input="$v.createDomainPassword.$touch()"
                      :state="$v.createDomainPassword.$dirty ? !$v.createDomainPassword.$error : null">
                    </b-form-input>
                    <div class="form-error" v-if="$v.createDomainPassword.$error">Votre mot de passe doit faire entre 2 et 255 caractères</div>
                  </b-form-group>
                </div>
              </div>

              <b-form-group id="create-domain-xpath_login-group" label="XPath Login" label-for="create-domain-xpath_login-input" class="mandatory-input">
                <b-form-input
                  v-model="createDomainXPathLogin" type="text"
                  maxlength="250"
                  placeholder="XPath du login (Ex : //*[@id='login'])"
                  id="create-domain-xpath_login-input"
                  @input="$v.createDomainXPathLogin.$touch()"
                  :state="$v.createDomainXPathLogin.$dirty ? !$v.createDomainXPathLogin.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createDomainXPathLogin.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="create-domain-xpath_password-group" label="XPath Password" label-for="create-domain-xpath_password-input" class="mandatory-input">
                <b-form-input
                  v-model="createDomainXPathPassword" type="text"
                  maxlength="250"
                  placeholder="XPath du password (Ex : //*[@id='password'])"
                  id="create-domain-xpath_password-input"
                  @input="$v.createDomainXPathPassword.$touch()"
                  :state="$v.createDomainXPathPassword.$dirty ? !$v.createDomainXPathPassword.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createDomainXPathPassword.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="create-domain-xpath_button-group" label="XPath Bouton" label-for="create-domain-xpath_button-input" class="mandatory-input">
                <b-form-input
                  v-model="createDomainXPathButton" type="text"
                  maxlength="250"
                  placeholder="XPath du password (Ex : //*[@id='button'])"
                  id="create-domain-xpath_button-input"
                  @input="$v.createDomainXPathButton.$touch()"
                  :state="$v.createDomainXPathButton.$dirty ? !$v.createDomainXPathButton.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createDomainXPathButton.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="create-domain-xpath_clics-group" label="XPath Clics" label-for="create-domain-xpath_clics-input">
                <b-form-input
                  v-model="createDomainXPathClics" type="text"
                  maxlength="250"
                  placeholder="XPath des clics avant login (séparés par ; si plusieurs)"
                  id="create-domain-xpath_clics-input"
                  @input="$v.createDomainXPathClics.$touch()"
                  :state="$v.createDomainXPathClics.$dirty ? !$v.createDomainXPathClics.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createDomainXPathClics.$error">Votre xpath doit faire entre 2 et 512 caractères</div>
              </b-form-group>

              <b-form-group id="create-domain-iframe-group" label="Id de l'iframe de login" label-for="create-domain-iframe-input">
                <b-form-input
                  v-model="createDomainIframeConnexionId" type="text"
                  maxlength="255"
                  placeholder="Id de l'iframe autour du login"
                  id="create-domain-iframe-input"
                  @input="$v.createDomainIframeConnexionId.$touch()"
                  :state="$v.createDomainIframeConnexionId.$dirty ? !$v.createDomainIframeConnexionId.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createDomainIframeConnexionId.$error">Votre iframe doit faire entre 2 et 255 caractères</div>
              </b-form-group>

              <b-button
                pill block type="submit" variant="outline-dark" class="mt-2"
                :disabled="isInvalidDomain">
                  Créer votre domaine
              </b-button>
            </b-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { APIDomain } from '@/api/APIDomain'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiDomain = new APIDomain()

export default {
  name: 'DomainCreation',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      createDomainName: '',
      createDomainDomainName: '',
      createDomainType: 'PPR',
      createDomainUrlLogin: '',
      createDomainLogin: '',
      createDomainPassword: '',
      createDomainXPathLogin: '',
      createDomainXPathPassword: '',
      createDomainXPathButton: '',
      createDomainIframeConnexionId: '',
      createDomainXPathClics: '',
      createDomainInProcess: false,

      createDomainTypeOptions: [
        { text: 'Presse quotidienne régionale', value: 'PQR' },
        { text: 'Presse hebodmadaire régionale', value:  'PHR' },
        { text: 'Presse professionnelle', value:  'PPR' },

      ],
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidDomain () {

      if (this.$v.$invalid) {
        return true
      }
      return false
    },
    isLoading () {
      if (this.createDomainInProcess) {
        return true
      }
      return false
    },
  },
  validations: {
    createDomainName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    createDomainDomainName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    createDomainType: {
      required
    },
    createDomainUrlLogin: {
      minLength: minLength(2),
      maxLength: maxLength(1024),
      required
    },
    createDomainLogin: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    createDomainPassword: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    createDomainXPathLogin: {
      minLength: minLength(2),
      maxLength: maxLength(512),
      required
    },
    createDomainXPathPassword: {
      minLength: minLength(2),
      maxLength: maxLength(512),
      required
    },
    createDomainXPathButton: {
      minLength: minLength(2),
      maxLength: maxLength(512),
      required
    },
    createDomainIframeConnexionId: {
      minLength: minLength(2),
      maxLength: maxLength(255),
    },
    createDomainXPathClics: {
      minLength: minLength(2),
      maxLength: maxLength(512),
    },
  },

  methods: {
    createDomain () {
      this.createDomainInProcess = true
      apiDomain.createDomain(
        this.token, this.createDomainDomainName, this.createDomainIframeConnexionId, this.createDomainLogin, this.createDomainName,
        this.createDomainPassword, this.createDomainType, this.createDomainUrlLogin, this.createDomainXPathClics,
        this.createDomainXPathLogin, this.createDomainXPathPassword, this.createDomainXPathButton)
      .then(() => {
        this.$refs.DomainCreatedModal.show()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('DomainCreation createDomain API Error : ' + String(error))
      })
      .finally(() => {
        this.createDomainInProcess = false
      })

    },
  }
}
</script>
